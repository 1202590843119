import type { ParentComponent } from "solid-js";
import { createContext, useContext } from "solid-js";
import { createStore } from "solid-js/store";
import { externalContentProviders } from "~/components/shared/GDPR/GdprPermissionRequest";

export type Purpose = "measure_content_performance";
export type Vendor = "c:youtube" | "c:pianohybr-R3VKC2r4";

type PurposesStatus = Record<Purpose, boolean>;
type VendorsStatus = Record<Vendor, boolean>;

export type DidomiContext = [
  {
    purposes: PurposesStatus;
    vendors: VendorsStatus;
  },
  {
    getPurposeStatus?: (purpose: Purpose) => boolean;
    setPurposeStatus?: (purpose: Purpose, accept: boolean) => void;
    getVendorStatus?: (vendor: Vendor) => boolean;
    setVendorStatus?: (vendor: Vendor, accept: boolean) => void;
    hasGivenConsentForUrl?: (url: string) => boolean;
  },
];

const DidomiContext = createContext<DidomiContext>([
  {
    purposes: {
      measure_content_performance: false,
    },
    vendors: {
      "c:youtube": false,
      "c:pianohybr-R3VKC2r4": false,
    },
  },
  {},
]);

export const DidomiProvider: ParentComponent = (props) => {
  const [state, setState] = createStore({
      purposes: {
        measure_content_performance: false,
      },
      vendors: {
        "c:youtube": false,
        "c:pianohybr-R3VKC2r4": false,
      },
      youtubeConsent: false,
      performanceConsent: false,
    }),
    store: DidomiContext = [
      state,
      {
        getPurposeStatus(purpose: Purpose) {
          return state.purposes[purpose];
        },
        setPurposeStatus(purpose: Purpose, accept: boolean) {
          setState("purposes", purpose, accept);
        },
        getVendorStatus(vendor: Vendor) {
          return state.vendors[vendor];
        },
        setVendorStatus(vendor: Vendor, accept: boolean) {
          setState("vendors", vendor, accept);
        },

        /**
         * Returns true if the given url has the required permissions given by the user.
         *
         * If no provider is found for the given url, it returns true.
         *
         * @param url
         */
        hasGivenConsentForUrl(url: string) {
          const provider = Object.values(externalContentProviders).find(
            (provider) => provider.expression.test(url),
          );

          if (provider) {
            return (
              provider.requiredConsents.purposes.every(
                (purpose) => state.purposes[purpose],
              ) &&
              provider.requiredConsents.vendors.every(
                (vendor: Vendor) => state.vendors[vendor],
              )
            );
          }

          return true;
        },
      },
    ];

  return (
    <DidomiContext.Provider value={store}>
      {props.children}
    </DidomiContext.Provider>
  );
};

export function useDidomiContext() {
  const context = useContext(DidomiContext);
  if (context === undefined) {
    throw new Error(
      "useDidomiContext must be used within a DidomiContext.Provider",
    );
  }
  return context;
}

import type { Purpose, Vendor } from "~/contexts/DidomiContext";
import { useDidomiContext } from "~/contexts/DidomiContext";
import type { Component } from "solid-js";
import { Switch, Match } from "solid-js";
import { didomiEnablePurpose, didomiEnableVendor } from "~/lib/Didomi";

type ExternalContentProviderName = "youtube" | "tours";

type RequiredConsents = {
  vendors: Vendor[];
  purposes: Purpose[];
};

type ExternalContentProvider = {
  expression: RegExp;
  component: Component;
  requiredConsents: RequiredConsents;
};

type ExternalContentProviders = Record<
  ExternalContentProviderName,
  ExternalContentProvider
>;

export const externalContentProviders: ExternalContentProviders = {
  youtube: {
    expression: /youtube\.com\/embed/,
    requiredConsents: {
      vendors: ["c:youtube"],
      purposes: [],
    },
    component: () => {
      return <PermissionRequestYoutube />;
    },
  },
  tours: {
    expression:
      /altarea-cogedim\.iframe\.evimmo\.fr|\.custhome\.app|axeon\.fr|vertex-france\.com|\.virtualbuilding\.fr|\.realiz3d\.fr/,
    requiredConsents: {
      vendors: [],
      purposes: ["measure_content_performance"],
    },
    component: () => {
      return <PermissionRequestFor3Ds />;
    },
  },
};

export function GdprPermissionRequest(props: { string: string }) {
  const youtubeMatch = () => {
    const r =
      props.string &&
      props.string.match(externalContentProviders.youtube.expression);

    return r && r.length > 0 ? true : false;
  };

  const toursMatch = () => {
    const r =
      props.string &&
      props.string.match(externalContentProviders.tours.expression);
    return r && r.length > 0 ? true : false;
  };

  return (
    <Switch>
      <Match when={youtubeMatch()}>
        <PermissionRequestYoutube />
      </Match>
      <Match when={toursMatch()}>
        <PermissionRequestFor3Ds />
      </Match>
    </Switch>
  );
}

function PermissionRequestFor3Ds() {
  const [, { setPurposeStatus }] = useDidomiContext();

  return (
    <div class="rgpd-container" data-test="gdpr">
      <p>
        Les maquettes 3D sont désactivées. Autorisez le dépôt de cookies pour
        lire la 3D.
      </p>
      <p>
        <button
          type="button"
          class="btn"
          onClick={() => {
            didomiEnablePurpose("measure_content_performance");
            setPurposeStatus!("measure_content_performance", true);
          }}
          data-test="btn-gdpr-3d"
        >
          Autoriser
        </button>
      </p>
    </div>
  );
}

function PermissionRequestYoutube() {
  const [, { setVendorStatus }] = useDidomiContext();

  return (
    <div class="rgpd-container" data-test="gdpr">
      <p>
        Ce contenu est bloqué. Pour accéder au contenu, vous devez autoriser le
        dépôt des cookies YouTube, qui sont utilisés pour permettre la lecture
        des vidéos, mais également vous proposer des recommandations de vidéos
        et publicités personnalisées en fonction des sites et contenus que vous
        visitez et des publicités avec lesquelles vous interagissez. Plus
        d’informations sur la{" "}
        <a
          href="https://www.youtube.com/intl/ALL_fr/howyoutubeworks/user-settings/privacy/"
          target="_blank"
        >
          Politique de confidentialité de YouTube
        </a>
        . En refusant ces cookies, vous ne pourrez pas visionner les vidéos
        présentes sur notre site.
      </p>
      <p>
        <button
          type="button"
          class="btn"
          onClick={() => {
            didomiEnableVendor("c:youtube");
            setVendorStatus!("c:youtube", true);
          }}
          data-test="btn-gdpr-youtube"
        >
          Autoriser
        </button>
      </p>
    </div>
  );
}
